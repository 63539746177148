import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue';
import store from "@/store";
import { getGoogleSheetRoutes } from "../utils/googleSheetData";

const AdminPanel = () => import("../views/AdminPanel");
const TeamDetails = () => import("../views/TeamDetails");
const TransfersCenter = () => import("../views/TransfersCenter");
const AllTransfers = () => import("../views/AllTransfers");
const Login = () => import("../views/Login");
const Rules = () => import("../views/Rules");
// const PlayersTable = () => import("../views/PlayersTable.vue");
const PointsHelper = () => import("../views/PointsHelper.vue");

Vue.use(VueRouter);

const setPageTitle = (to, from, next) => {
  document.title = to.meta.title || "Fantasy Football Legends";
  return next();
};

const clearNotifications = (to, from, next) => {
  if (store.getters["notifications/notifications"]?.length) {
    store.dispatch("notifications/clearNotifications");
  }
  return next();
};

// eslint-disable-next-line no-unused-vars
const isAuthenticated = async (to, from, next) => {
  await store.dispatch("user/fetchLoggedUser");
  const loggedUser = store.getters["user/getLoggerUser"];
  if (!to.meta.requiresAuth) {
    return to.name === "Login" ? (loggedUser ? next("/") : next()) : next();
  }
  if (!loggedUser) {
    return next({
      path: "/login",
    });
  }
  return next();
};

const routes = [
  ...getGoogleSheetRoutes(),
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  //   meta: { requiresAuth: false, title: "FFL Home" },
  // },
  {
    path: "/admin",
    name: "AdminPanel",
    component: AdminPanel,
    meta: { requiresAuth: true, title: "Admin Panel" },
    async beforeEnter(to, from, next) {
      await store.dispatch("user/fetchLoggedUser");
      const loggedUser = await store.getters["user/getLoggerUser"];
      console.log(loggedUser);
      // CHECK IF LOGGED USER IS ADMIN
      if (+loggedUser?.roleId === 1) {
        next();
        return;
      }

      localStorage.removeItem("Access-Token");
      localStorage.removeItem("Refresh-Token");
      next("login");
    },
  },
  {
    path: "/team-details/:userId",
    component: TeamDetails,
    props: true,
    name: "UserPage",
    meta: { requiresAuth: true, title: "My Team" },
  },
  {
    path: "/transfers-center",
    component: TransfersCenter,
    props: true,
    name: "TransfersCenter",
    meta: { requiresAuth: false, title: "Transfers Center" },
  },
  {
    path: "/login",
    component: Login,
    props: true,
    name: "Login",
    meta: { requiresAuth: false, title: "Login" },
  },
  {
    path: "/all-transfers",
    component: AllTransfers,
    props: true,
    name: "AllTransfers",
    meta: { requiresAuth: true, title: "All Transfers" },
  },
  {
    path: "/rules-prizes",
    component: Rules,
    props: true,
    name: "Rules",
    meta: { requiresAuth: false, title: "FFL Rules" },
  },
  {
    path: "/points-helper",
    component: PointsHelper,
    props: true,
    name: "PointsHelper",
    meta: { requiresAuth: false, title: "FFL Points Helper" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(clearNotifications);

// router.beforeEach(isAuthenticated);

router.beforeEach(setPageTitle);

export default router;
