<template>
  <v-app>
    <v-main>
      <v-row :no-gutters="true">
        <v-col>
          <NavBar />
        </v-col>
      </v-row>
      <div id="app">
        <Header />
        <!-- <v-btn class="ml-16" color="green" @click.prevent="logIn"
          >Temp Log In
        </v-btn> -->
        <!-- <Logos /> -->
        <!-- <div class="content-wrapper" transition="slide-x-transition"> -->
        <router-view />
        <!-- </div> -->
        <!-- <Footer /> -->
      </div>
    </v-main>
    <Notification />
  </v-app>
</template>

<script>
import axios from "axios";
import NavBar from "./components/NavBar.vue";
import Header from "./components/common/Header";
import Notification from "./components/common/Notification";
import { API_URL } from "@/common/entrypoint";
import { mapActions } from "vuex";
// import Logos from "./components/common/Logos/Logos";
// import Logos from "./components/common/Logos/Logos";

export default {
  name: "App",
  components: {
    Header,
    // Footer,
    Notification,
    NavBar,
  },

  data: () => ({}),
  methods: {
    ...mapActions("auth", ["login"]),
    logIn() {
      let data = JSON.stringify({
        email: "superadmin@info.dev",
        password: "password",
      });

      let config = {
        method: "post",
        url: `${API_URL}login`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          localStorage.setItem("Access-Token", response.data.data.accessToken);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "./common/breakpoints.scss";

.up {
  text-transform: uppercase;
}

.theme--light.v-application {
  background: #eaeaea !important;
}

main {
  background-color: lightgrey;
}
#app {
  // background-color: lightgrey;
  height: 89vh;
}

.content-wrapper {
  margin: 0 50px;

  @media #{$mobile} {
    margin: 0 10px;
  }
  @media #{$tablet} {
    margin: 0 10px;
  }
}
</style>
