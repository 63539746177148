import { postResource } from '../../utils/resourceRequests';
import { POST_RESOURCE_PATH } from '../../common/apiRequests';

const state = {};

const getters = {};

const actions = {
  async login(_, payload) {
    try {
      if (
        localStorage.getItem('Access-Token') &&
        localStorage.getItem('Access-Token') !== 'undefined' &&
        localStorage.getItem('Refresh-Token') &&
        localStorage.getItem('Refresh-Token') !== 'undefined'
      ) {
        return;
      }

      const response = await postResource({
        resourcePath: POST_RESOURCE_PATH.LOGIN,
        payload,
      });

      localStorage.setItem('Access-Token', response?.accessToken);
      localStorage.setItem('Refresh-Token', response?.refreshToken);

      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async logout() {
    try {
      await postResource({
        resourcePath: POST_RESOURCE_PATH.LOGOUT,
      });
      console.log(1);
      localStorage.removeItem('Access-Token');
      location.reload();
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
