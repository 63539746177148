const Wrapper = () => import("../components/TempSheetViews/Wrapper");

const iFrameLinks = [
  {
    route: "players",
    iFrameLink:
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vRvw88iJ0Qwh2zwv_-i4u3A0iQBF8Rp5IxxKfKZYjpnf7DLTgCv5Jv7HeXI0Rn322frv-DNErdV7zAJ/pubhtml?gid=1471122047&amp;single=true&amp;widget=true&amp;headers=false",
  },
  {
    route: "pele",
    iFrameLink:
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vRqmNa2xZHA1978Dx2CpSIpR0yCOtyn4XKO5fI7-cshG3qQw2lbhT9qye2oWiUOWZJLx6vmevHM5Jax/pubhtml?widget=true&amp;headers=false",
  },
  {
    route: "maradona",
    iFrameLink:
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vQGELLMqympuFrereC-KU0GLBUEkLSVOPHIlsxjAO999J9ULKkIQl-dsT8ZT400QS4gYeFbVPLDdYrw/pubhtml?widget=true&amp;headers=false",
  },
  {
    route: "transfers",
    iFrameLink:
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vS4onO3wEWlN937VMVlponsEw1zYcJrJMBCeJ8zt-aTBJ4l9Ugo2Sf-viQkAgXd-dJOCNDROF2EkBo1/pubhtml?widget=true&amp;headers=false",
  },
  {
    route: "h2h",
    iFrameLink:
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vRHmF3uEiKIMsTJppnbR7CfbASMbrhaNpBzQrhkyPOv2NR7k1N3loLYaZWTPNBbrrpKc04ytbfW-pw0/pubhtml?widget=true&amp;headers=false",
  },
  {
    route: "cup",
    iFrameLink:
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vRZhLtJGugFwxCRmJARIHAz6ksOBE5h3iNtnpTG2liqZmG3P7sL6orghs9Tjfgfw3XRV8R_f4s0f5NC/pubhtml?widget=true&amp;headers=false",
  },
  {
    route: "schedule",
    iFrameLink:
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vSeXcvXaX0jM9_GTb0P5bPMOcEmvB5pY0nM3iNULq8w4rJ31PHQOb_SElVEzmvpY_xZRL1Dn8Qy4Wpf/pubhtml?widget=true&amp;headers=false",
  },
];

export const getGoogleSheetRoutes = () =>
  iFrameLinks.map(({ route, iFrameLink }) => ({
    path: `/${route}`,
    name: route.toUpperCase(),
    component: Wrapper,
    props: {
      iFrameLink,
    },
    meta: { requiresAuth: false, title: `FFL: ${route.toUpperCase()}` },
  }));

export const getGoogleSheetNavLinks = () =>
  iFrameLinks.map(({ route }) => ({
    title: route.toUpperCase(),
    path: `/${route}`,
    show: true,
  }));
